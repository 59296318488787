export const environment = {
  production: true,
  isLive: false,
  AppName: "API Connector",
  multi_API_ENDPOINT: {
    amazon_multi_production: {
      name: "Amazon-Multi-Production",
      API_ENDPOINT: "https://amazon-sales-channel-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjM2Y2ZjYTgxNzkwOGMwNzg0MGFhOWY3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjM2ZDBiYWU5ZDAxYTY2OTIxMDI1ZDUyIn0.k3NiJh1LoqzapwQiXkqpgn-JIoFhwfntaWHcANS3PL49xuasdSpZhhVcMTerkRU0gcpLTMKTowvuM4GCcDtOYjhxPSpVVQvbCZKyc0JRNj4bTW7HX8fhDZskbqec8S2XyMhUGZcSz2qVwZRJd9ZlyxUeifk93L4QeYydgO4jt0RV_HlxlZXID_mSaLDyMmoeYw5jXggN7ihCkxQ2l7avHeyp-SJ7ZIVt_tR_Z4sKGd9q4E-hmJE9JBseUag_zwCqpKKPYRKPVMPU-q04vQ4WwBtRHYkGZVn9JeIbcJJHJYs6v4oQNWz9Gu2ONExq8g-bv34HtmMzW45Jr2diNsXA0A",
    },
    amazon_multi_staging:{
      name: "Amazon-Multi-Staging",
      API_ENDPOINT: "https://staging-amazon-sales-channel-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjNkY2VmOWFmMWU0NTc3MWU0MDQ5YTc3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjNkY2VmYTNjZTIxZmNlMmI2MDViNmEyIn0.rNqC8Ej_Jf-thddEgKxdxe1Jg6qH8YjHuNlRus8P1Y3Gb8t1-rzLbK7j8sxQjAdpQS3I9mrCPSfVa828iz7t28exSEi6f2Ou5Jt-HhGAWELC6uc-CFkpNfHxHYGQw9GUks1gO9fwdd9VSCosHzljaxIET50_-9hvxYd4GLEPy7ZzpU6kZEuSDe4XsYgYtZNMED4UnVohMU-1hc-JguvzueA5fh4wsKuj_ZeEGnBmt-kMYpiHUDRM41iiH5k6rwYFHicfJAz8wEYsUcaV0RnxR3OJ8TjaHQXsLUWlnyCoQ2jsFvpO8azdwxURxscXUqdUuaxOIep-2db1GXTh_O3Kqw",      
    },
    amazon_multi_development:{
      name: "Amazon-Multi-Development",
      API_ENDPOINT: "https://dev-amazon-sales-channel-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjRhNTQ5YzdlNjc2NjcyZjMzMGM2NTU3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjRhNTUwNzJhYTUzNGZmYzFhMDc0ZWQyIn0.m3jp9VqG-3mLun9Fd7FORILw1bvQbrhv_aTT7FF1OhPlvqsQ46G2Pk81v49kVF1ibqHGffGQHBJKyzE62wg44C-pAPBQcRxNomjigxs-tQLLVw9g75oVYz9fkk0QRY4xeDN8YKjxSNvjgGZ-GxxTSNDQrooexZd_ZHtT6MjYpmYcQkXtLl7I2kciK8lHeLRy4YUr-KL82ehyQwE8DZm_D9C52c9rH_8Tcry4JqwjUSmhOF63tVckz8lBf0wYe12p5qpkls2mfsjtUw6CiGHPFUmMrQ_lL0HStyuGdsvauaNFJIVrBVS0LczlBgcKAA2I0DD2z6dfMvujd-JakU1iBg",      
    },
  },
};
